<template>
    <div class="unOuter mainOuter">
        <div class="container_un">
            <div class="subWhite animated fadeInLeft ani_delay05">
                <div class="sub_bg_bot"></div>
                <div class="subTitBox">
                    <p id="t_page_title" class="subTitle">{{$t('withdrawal.title')}}</p>
                </div>
                <div class="bTable">
                    <div novalidate="" method="post" name="form_withdraw" id="form_withdraw" class="form-horizontal ng-untouched ng-pristine ng-valid">
                        <div id="wallet-balances--block">
                          <ul>
                            <li v-for="balance in balances" :class="{active: balance.coin === coin_type, lock: balance.lock_info && balance.lock_info.status === true && is_lock}" @click="ChangeSelectedToken(balance.coin)">
                              <p class="symbol" :class="balance.coin"><img :src="`/img/symbol/${(balance.coin === 'TRX' ? 'TRON' : balance.coin)}.png`" :alt="balance.coin" onerror="this.src='/img/symbol/default.png'" /></p>
                              <p class="name">{{$t('withdrawal.balance')}}{{balance.coin === 'TRX' ? 'TRON' : balance.coin}}</p>
                              <p class="balance">{{balance.balance}}</p>
                            </li>
                          </ul>
                        </div>

                      <template v-for="balance in balances">
                        <div class="lockup--notice" v-if="balance.lock_info && balance.lock_info.status === true && is_lock">
                          <p style="display: none; ">* Lockup: {{balance.lock_info.start}} ~ {{balance.lock_info.end}}</p>
                          <p>* {{$t('withdrawal.noticeWithdrawalAfterLockup')}}</p>
                        </div>
                      </template>

                      <div class="form-group">
                        <p id="t_withdrawable_amt" class="sub th">{{$t('withdrawal.withdrawCoin')}}</p>
                        <select class="td input ng-untouched ng-pristine ng-valid" v-model="coin_type" @change="ChangeSelectedToken(coin_type)">
                          <option :value = item.code class="ng-star-inserted text-black" v-for="(item,index) in coin_list" :key="'l_'+index">{{item.text}}</option>
                        </select>
                      </div>

                        <div class="w33L">
                            <p id="t_withdraw_address" class="sub th">{{$t('withdrawal.withdrawAddress')}}</p>
                            <p class="td input" style="position: relative;">
                                <input type="text" id="withdraw_address" name="withdraw_address" v-model="w_address" ng-reflect-name="withdraw_address" class="ng-untouched ng-pristine ng-valid" :disabled="is_lock">
                            </p>
                        </div>
                        <div class="w33L">
                            <p id="t_apply_amt" class="sub th">{{$t('withdrawal.withdrawAmount')}}</p>
                            <p class="td input">
                                <input type="text" id="apply_amt" ng-reflect-pattern="[0-9]*" pattern="[0-9]*" numberonly="true" name="apply_amt" v-model="w_balance" ng-reflect-name="apply_amt" class="ng-untouched ng-pristine ng-valid" :disabled="is_lock">
                            </p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="w33L">
                            <p id="t_apply_amt" class="sub th">{{$t('withdrawal.baseFee')}}</p>
                            <p class="td input">
                                <input type="text" id="apply_amt" numberonly="true" name="apply_amt" v-model="w_fee" ng-reflect-name="apply_amt" class="ng-untouched ng-pristine ng-valid" :disabled="is_lock">
                            </p>
                        </div>
                        <div class="clearfix"></div>

                        <div class="w33L">
                          <p id="t_apply_amt" class="sub th">{{$t('withdrawal.otpCode')}}</p>
                          <p class="td input">
                            <input type="text" id="otp_code" numberonly="true" name="otp_code" v-model="otp_code" ng-reflect-name="otp_code" class="ng-untouched ng-pristine ng-valid" required :disabled="is_lock">
                          </p>
                        </div>

                        <div class="clearfix"></div>
                        <div class="bWrap" v-if='wallet_status=="N"'>
                            <p name="submitBtn" class="bOk card_bg5" :class="{disabled: is_lock === true}" @click="WalletWithdraw">{{$t('withdrawal.withdraw')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
    data(){
        return{
            coin_type:'USDT',
            coin_list:[],
            address : '',
            w_fee : '10',

            balances: [],

            w_address : '',
            w_balance : '',
            wallet_status : 'Y',

            is_lock: false,

            otp_code: '',
        }
    },

  beforeMount() {
    this.PreloadWalletBalance()
  },

  mounted(){
        this.CheckOTP();
        this.GetWalletInfo();
    },
    methods:{
        ChangeSelectedToken(coin) {
          this.coin_type = coin

          this.SetLock()
          this.GetWithdrawFee()
        },

      SetLock() {
          const coin = this.balances.find((element) => element.coin === this.coin_type)

          this.is_lock = coin.lock_info === undefined || coin.lock_info === null ? false: coin.lock_info.status
        },

      PreloadWalletBalance() {
        this.$axios.post('/member/coin/GetActiveCoinListForHistory', {}).then(
            res => {
              if (res.status !== 200) {
                return
              }

              if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )

                return
              }

              const e_body = res.data.body;
              const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res)

              let balances = []
              for (const row of body.lists) {
                balances = balances.concat({
                  coin: row,
                  balance: 0
                })
              }

              this.balances = balances
            }
        )
      },

        GetWalletInfo(){

            this.$axios.post('/member/coin/GetWalletInfo', {}).then(
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.address = body.wallet.address;
                            this.wallet_status = body.wallet.status;

                            this.balances = body.wallet.balances

                            for (const balance of this.balances) {
                              this.coin_list = this.coin_list.concat({
                                code: balance.coin,
                                text: balance.coin === 'TRX' ? 'TRON' : balance.coin,
                              })
                            }

                            this.SetLock()
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/sign/login";
                                }
                            )
                        }
                    }
                }
            )
        },
        GetWithdrawFee(){
            if(this.coin_type === 'USDT' || this.coin_type === 'MCS'){
                this.w_fee = 10;
            }else{
                this.w_fee = 1.1;
            }
        },
        CheckOTP() {
          this.$axios.post('/member/profile/CHECK_OTP', {}).then(
              res => {
                if (res.status == 200) {
                  if (res.data.code == '200') {
                    const e_body = res.data.body;
                    const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)

                    if (body.otp_able !== 'Y') {
                      this.$alert(this.$i18n.t('withdrawal.alertSetOPTFirst')).then(
                          () => {
                            window.location.href = '/otp'
                          }
                      )
                    }

                  } else if (res.data.code == '9999') {
                    this.$store.dispatch('SETLOGOUT').then(
                        () => {
                          window.location.href = "/sign/login";
                        }
                    )
                  }
                }
              }
          )
        },
        async WalletWithdraw(){
            if (this.is_lock) {
              return
            }

            const coin = this.coin_type;
            const w_address = this.w_address.trim();
            const w_balance = this.w_balance.trim();

            const body = {coin,w_address,w_balance};

            if(w_address === '') {
                this.$alert(this.$i18n.t('withdrawal.alertAddress'))
                return false;
            }

            if (this.address === w_address) {
                this.$alert(this.$i18n.t('withdrawal.notAllowSelfAddress'))
            }

            if(w_balance === '' || w_balance <= 0) {
                this.$alert(this.$i18n.t('withdrawal.alertAmount'))
                return false;
            }

            if (this.otp_code === '') {
              this.$alert(this.$i18n.t('withdrawal.alertRequiredOTPCode'))
              return false
            }

            // otp 인증
            let req = CryptoJS.AES.encrypt(JSON.stringify({
              otp_code: this.otp_code
            }),process.env.VUE_APP_SECRET_TOKEN).toString();

            let flag = false

            await this.$axios.post('/member/profile/OTPAuthCheck', {req}).then(
                res => {
                  if(res.status == 200){
                    if(res.data.code == '100'){
                      this.$alert(this.$i18n.t('withdrawal.alertOTPNotMatches'))
                    } else {
                      flag = true
                    }
                  }
                }
            )

            if (!flag) {
              return
            }

            // 출금 진행
            req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            await this.$axios.post('/member/coin/Transfer', {req}).then(
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            this.$alert(this.$i18n.t('withdrawal.complete')).then(
                                ()=>{
                                    this.$router.go()
                                }
                            )
                        }else if(res.data.code=='300') {
                          this.$alert(this.$i18n.t('withdrawal.alertCheckAddress'))
                        }else if(res.data.code=='301') {
                          this.$alert(this.$i18n.t('withdrawal.notAllowSelfAddress'))
                        } else if (res.data.code === 9001) {
                          this.$alert(res.data.msg);
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/sign/login";
                                }
                            )
                        }
                    }
                }
            )
        },
    }

}
</script>

<style lang="scss" scoped>
@import "@/assets/css/wallet";

.lockup--notice {
  margin-top: 50px;
  padding-left: 10px;
  text-align: center;
  color: #ff0000;
  font-size: 18px;
  font-weight: bold;

  > p {
    margin-bottom: 10px;
  }
}

.bWrap {
  .bOk {
    &.disabled {
      background: #1b1d25;
      color: #4a4a4a;
    }
  }
}
</style>
